<template>
  <div class="basket-popup--full">
    <div class="basket__list">
      <uc-link
        v-for="(basketItem, index) in basketItems"
        :key="`basket__list-item-${index}-${basketItem.key}`"
        :to="localePath({ name: 'product-detail-page', params: { id: basketItem.slug } })"
        class="basket__list-item"
      >
        <uc-image :img="basketItem.currentImage" :width="'120'" :height="'120'" alt="banner" title="image" />
        <div class="basket__list-info">
          <uc-render-html class="basket__list-title" root-element="div" :html="basketItem.title" />
          <div class="basket__list-cost">
            <div v-if="basketItem.isAvailableForOrder" class="basket__list-quantity">{{ basketItem.count }}</div>
            <div v-if="basketItem.itemType !== ITEM_TYPE_GIFT" class="basket__list-price">
              <span v-if="basketItem.isAvailableForOrder">{{ basketItem.finalPrice | currency }}</span
              ><span v-else class="not-available">{{ $t('Распродано') }}</span>
            </div>
            <div v-if="basketItem.itemType === ITEM_TYPE_GIFT" class="basket__list-price">
              <span class="gift">{{ $t('Подарок') }}</span>
            </div>
          </div>
        </div>
      </uc-link>
    </div>
    <div class="basket__price">
      <div class="basket__list-item">
        <div class="basket__list-item-title">{{ $t('Общая сумма') }}:</div>
        <div class="basket__list-price">
          <b>{{ totalPrice.totalFinalPrices | currency }}</b>
        </div>
      </div>
      <div v-if="totalPrice.totalCheckedProfit" class="basket__list-item">
        <div class="basket__list-item-title">{{ $t('Экономия') }}:</div>
        <div class="basket__list-sale">{{ totalPrice.totalCheckedProfit | currency }}</div>
      </div>
    </div>
    <div class="basket__actions">
      <span class="basket__action-link" @click="$eventBus.$emit('basket_modal:display')">
        {{ $t('В корзину') }}
      </span>
      <uc-link v-if="!isEmptyAvailable" :to="localePath({ name: 'order-page' })" class="uc-button uc-button-green">
        {{ $t('Оформить заказ') }}
      </uc-link>
    </div>
  </div>
</template>

<script>
import { basketModels } from 'u-models';
const { ITEM_TYPE_GIFT } = basketModels.basketItemType;

export default {
  computed: {
    basketItems() {
      const basket = this.$basket.getBasket();
      return basket.sortedBasketItems;
    },
    totalPrice() {
      return this.$basket.getTotalBasketPrice();
    },
    isEmptyAvailable() {
      return !this.$store.state.basket.basket || this.$store.state.basket.basket.isEmptyAvailable;
    },
    ITEM_TYPE_GIFT: () => ITEM_TYPE_GIFT
  }
};
</script>
